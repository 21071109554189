@import '../../styles/customMediaQueries.css';

.section {
  margin: 0 24px 50px 24px;

  @media (--viewportMedium) {
    width: min(calc(var(--contentMaxWidth)), 100vw);
    margin: 0 auto 117px auto;
  }
}

.pageSection {
  /*background: var(--colorFailLight);*/
  padding-top: 20px;

  @media (--viewportLarge) {
    padding-top: 40px;
  }
}

.mainTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 49px;
  }
}

.subTitle {
  text-align: center;
  margin-top: 14px !important;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 34px;
  }
}

.colored {
  color: #323CB7;
}

.list {

}

.item {
  background: var(--colorFailLight);
  border-radius: 14px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;

  @media (--viewportLarge) {
    padding: 24px 32px 10px 32px;
    margin-bottom: 30px;
  }
}

.itemTitle {
  padding: 0;
  display: flex;
  margin-bottom: 17px;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;

  @media (--viewportLarge) {
    font-size: 24px;
  }
}

.titleNumber {
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 32px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #FFAEAB;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  margin-right: 16px;
}

.innerList {
  padding-left: 49px;
}

.innerItem {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 30px;
  }
}

.bold {
  font-weight: 700;
}
